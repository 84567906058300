.main {
  background-color: #ffffff;
  padding-top: 200px;
  text-align: left;
  margin: 0 auto;
}

.varsBlock {
  width: 100%;
  margin-bottom: 12px;
  border: 2px solid #00BEB4;
  padding: 5px;
}

.name {
  font-weight: 700;
}

.id {
  font-weight: 700;
  margin-bottom: 3px;
}

.block {
  max-width: 800px;
}

.descr {
  font-weight: 700;
  margin: 10px 0px;
}

.p {
  margin-bottom: 8px;
}

.json {
  border: 2px solid #00BEB4;
  padding: 5px;
}

.label {
  margin-right: 10px;
}

.input {
  border-radius: 10px;
  width: 100%;
  padding: 2px 5px;
  outline: none;
  border: 2px solid #00BEB4;
  display: block;
  transition: all 0.3s linear;
  margin-bottom: 8px;
  border-radius: 10px;
}

.input:focus,
input:active {
  box-shadow: 0px 1px 8px 2px rgba(34, 60, 80, 0.4);
}

.button {
  margin: 14px 0;
  padding: 10px;
}

.buttonTrace {
  max-width: 140px;
  padding: 3px;
  font-size: 14px;
  margin: 0;
}

.tabs li {
  width: 100%;
}

.textarea {
  border-radius: 10px;
  resize: none;
  overflow: auto;
  min-height: 100px;
  width: 100%;
  padding: 2px 5px;
  outline: none;
  border: 2px solid #00BEB4;
  display: block;
  transition: all 0.3s linear;
  margin-bottom: 8px;
}

.textareaResult {
  border-radius: 10px;
  resize: none;
  overflow: auto;
  min-height: 400px;
  width: 100%;
  padding: 2px 5px;
  outline: none;
  border: 2px solid #00BEB4;
  display: block;
  transition: all 0.3s linear;
  margin-bottom: 8px;
}

.textarea:focus,
textarea:active {
  box-shadow: 0px 1px 8px 2px rgba(34, 60, 80, 0.4);
}

.descrBlock {
  display: flex;
  align-items: center;
}

.svg,
.svgClicked {
  width: 20px;
  min-width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 5px;
  transition: all 0.3s linear;
}

.svgClicked path {
  fill: #00BEB4;
  stroke: #00BEB4
}

.svg path,
.svgClicked path {
  transition: all 0.3s linear;
}

/* .svg:hover path {
  fill: #00BEB4;
  stroke: #00BEB4
} */

.textareaJson {
  resize: none;
  overflow: auto;
  height: 90vh;
  width: 100%;
  display: block;
  border: none;
}

.cross {
  text-align: right;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s linear;
  font-size: 20px;
  margin-bottom: 6px;
}

.cross:hover {
  color: #00BEB4;
}

.jsonBlock {
  background: #fff;
  border: 2px solid #00BEB4;
  min-height: 70vh;
  padding: 2px 5px;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
}

.resultBlock {
  position: relative;
}

.flex {
  display: flex;
  justify-content: space-between;
  column-gap: 40px;
}

.requestParams_flex {
  position: relative;
}

.requestSubBlock {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
}

.saveFlexBlock {
  width: 30%;
  order: 1;
}

.saveSvg {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.saveSvg:hover path {
  stroke: #00BEB4;
  fill: #00BEB4
}

.saveVarsBlockOpen {
  display: block;
}

.saveVarsBlockHidden {
  display: none !important
}

.flexResult {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.traceResult {
  display: block;
  position: absolute;
  width: 100%;
  top: -30px;
}

.textareaTraceResult {
  resize: none;
  overflow: auto;
  min-height: 440px;
  width: 100%;
  padding: 2px 5px;
  outline: none;
  border: 2px solid #00BEB4;
  transition: all 0.3s linear;
  margin-bottom: 8px;
  margin-top: 0px;
}

.traceResultHidden {
  display: none;
}

@media (max-width: 767px) {
  .flex {
    display: block;
  }

  .saveFlexBlock {
    width: 100%;
    order: 0;
    text-align: right;
    margin-bottom: 10px;
  }

  .requestParams_flex {
    display: block;
    position: flex;
  }

  .requestParams_flex {
    position: relative;
  }

  .jsonBlock {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 66px;
    left: 0;
    background: white;
  }

  .mainRequestsBlock {
    min-height: 100vh;
  }

  .requestSubBlockHidden .label,
  .requestSubBlockHidden .input,
  .requestSubBlockHidden .p {
    opacity: 0;
  }

  .requestSubBlock {
    display: block;
  }
}

/* @media (max-width: 719px) {
  .jsonBlock {
    top: 66px;
  }
} */
/* 
@media (max-width: 436px) {
  .descrBlock .descr {
    font-size: 14px;
    margin: 3px 0;
  }

  .jsonBlock {
    top: 46px;
  }
}

@media (max-width: 353px) {
  .jsonBlock {
    top: 65px;
  }
} */

@media (min-width: 767px) {
  .svg:hover path {
    fill: #00BEB4;
    stroke: #00BEB4
  }
}